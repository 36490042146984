import i18n from "@/libs/i18n";
export default [
    {
        path: "/vendor-roles",
        name: "list-vendor-roles",
        component: () => import("@/views/settings/vendorRoles/ListRoles/index.vue"),
        meta: {
            pageTitle: "settings/vendorRoles/list-vendor-roles",
            breadcrumb: [
                {
                    text: "g.settings/vendorRoles/list-vendor-roles",
                    active: true,
                },
            ],
        }
    },
    {
        path: "/add-vendor-role",
        name: "add-vendor-role",
        component: () => import("@/views/settings/vendorRoles/AddRole/index.vue"),
        meta: {
            pageTitle: "settings/vendorRoles/add-vendor-role",
            breadcrumb: [
                {
                    text: "g.settings/vendorRoles/add-vendor-role",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/edit-vendor-role/:id",
        name: "edit-vendor-role",
        component: () => import("@/views/settings/vendorRoles/AddRole/index.vue"),
        meta: {
            pageTitle: "g.settings/vendorRoles/edit-vendor-role",
            breadcrumb: [
                {
                    text: "g.settings/vendorRoles/edit-vendor-role",
                    active: true,
                },
            ],
        },
    },
];
