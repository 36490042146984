export default [
  {
    path: "/users-list",
    name: "users",
    component: () => import("@/views/users/usersList/index.vue"),
    meta: {
      permissions: { users: { index: true } },
    },
  },
];
