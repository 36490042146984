export default [
  {
    path: "/show-order/:id",
    name: "show-order",
    component: () => import("@/views/orders/showOrder/index.vue"),
    meta: {
      permissions: {
        orders: { show: true },
      },
    },
  },
  {
    path: "/orders-list",
    name: "orders-list",
    component: () => import("@/views/orders/ordersList/index.vue"),
    meta: {
      permissions: {
        orders: { index: true },
      },
    },
  },
];
