export default [
  {
    path: "/roles",
    name: "list-roles",
    component: () => import("@/views/settings/roles/ListRoles/index.vue"),
    meta: {
      permissions: { roles: { index: true } },
      pageTitle: "settings/roles/list-roles",
      breadcrumb: [
        {
          text: "settings/roles/list-roles",
          active: true,
        },
      ],
    },
  },
  {
    path: "/add-role",
    name: "add-role",
    component: () => import("@/views/settings/roles/AddRole/index.vue"),
    meta: {
      permissions: { roles: { store: true } },

      pageTitle: "settings/roles/add-role",
      breadcrumb: [
        {
          text: "settings/roles/add-role",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-role/:id",
    name: "edit-role",
    component: () => import("@/views/settings/roles/AddRole/index.vue"),
    meta: {
      permissions: { roles: { update: true, show: true } },
      pageTitle: "settings/roles/edit-role",
      breadcrumb: [
        {
          text: "settings/roles/edit-role",
          active: true,
        },
      ],
    },
  },
];
