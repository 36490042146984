export default {
    namespaced: true,
    state: {
        isApproved: false,
    },
    mutations: {
        setApproved(state, payload) {
            state.isApproved = payload;
        },
    },
    actions: {
        setIsApproved({commit}, payload) {
            commit('setApproved', payload);
        }
    },
}
