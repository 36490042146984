export default [
  {
    path: "/faq-list",
    name: "FAQList",
    component: () => import("@/views/faq/faqList/index.vue"),
    meta: {
      permissions: {
        FAQs: { index: true },
      },
    },
  },
];
