
import { initializeApp } from "firebase/app";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD9f0Jtg_HDbQy4IZeQvCV7TkOdzVozXIo",
  authDomain: "aljoodmed-e1ed7.firebaseapp.com",
  projectId: "aljoodmed-e1ed7",
  storageBucket: "aljoodmed-e1ed7.appspot.com",
  messagingSenderId: "925944474820",
  appId: "1:925944474820:web:695dd7d5875b5e847fad91",
  measurementId: "G-NQ6NF3GPX2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app