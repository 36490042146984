export default [
  {
    path: "/add-slider/",
    name: "add-slider",

    component: () => import("@/views/settings/sliders/addSlider/index.vue"),
    meta: {
      permissions: { sliders: { store: true } },
    },
  },
  {
    path: "/edit-slider/:id",
    name: "edit-slider",
    component: () => import("@/views/settings/sliders/addSlider/index.vue"),
    meta: {
      permissions: { sliders: { "edit-slider-data": true } },
    },
  },
  {
    path: "/sliders-list",
    name: "slidersList",
    component: () => import("@/views/settings/sliders/slidersList/index.vue"),
    meta: {
      permissions: { sliders: { index: true } },
    },
  },
];
