import countries from "./countries";
import roles from "./roles";
import vendorRoles from "@/router/routes/settings/vendorRoles";
import sliders from "./sliders";

export default [
  {
    path: "/general-settings",
    name: "settings",

    component: () => import("@/views/settings/index.vue"),
    meta: {
      permissions: { settings: { index: true } },
    },
  },
  {
    path: "/email-settings",
    name: "emailSetting",
    component: () => import("@/views/settings/emailSetting/index.vue"),
    meta: {
      permissions: { settings: { index: true } },
    },
  },
  {
    path: "/social-settings",
    name: "socialLinksSettings",
    component: () => import("@/views/settings/loginSettings/index.vue"),
    meta: {
      permissions: { settings: { index: true } },
    },
  },
  ...countries,
  ...roles,
  ...vendorRoles,
  ...sliders,
];
