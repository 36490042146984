import axiosIns from "@axios";
import { handelPermission } from "@/auth/utils";

export default {
  state: {
    userId: null,
    userData: {},
    is_blocked: false,
    role: null,
    permissions: [],
    formattedPermissions: {},
    sub_Permission: {},
    sub_Permissions: {},
    avatar: null,
  },
  getters: {
    getUserPermissions(state) {
      return state.formattedPermissions;
    },
  },
  mutations: {
    set_Formated_Permission(state, payload) {
      state.formattedPermissions = payload;
    },
  },
  actions: {
    FETCH_DATA_USER({ state, commit, dispatch }) {
      axiosIns
        .get(`auth/profile?guard=web`)
        .then(async (res) => {
          if (res.status === 200) {
            const { data } = res.data;
            const userData = { ...data };
            delete userData.roles;
            state.userData = userData;
            state.userId = data.id;
            state.is_blocked = data.is_blocked;
            state.role = data.roles[0]?.name;
            state.permissions = data.roles?.filter(
              (ele) => ele.guard_name === "web"
            )[0]?.permissions;
            window.localStorage.setItem("userData", JSON.stringify(data));
            dispatch("get_notification");
            const formattedPermission = handelPermission(
              data.roles?.filter((ele) => ele.guard_name === "web")[0]
                ?.permissions
            );
            commit("set_Formated_Permission", formattedPermission);
            if (data.is_blocked) {
              localStorage.clear();
              window.location.href = "/";
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * GET_PERMISSION({ state }, payload)
     * @param {string} payload
     * @returns {void} nothing
     */
    GET_PERMISSION: ({ state }, payload) => {
      if (typeof payload !== "string") {
        throw new Error("payload must be an string");
      }

      const permission = state.permissions.find(
        (item) => item.name === payload
      );
      permission?.permission?.sub_permissions?.forEach(
        (item) => (state.sub_Permission[item.name] = true)
      );
    },

    /**
     * GET_PERMISSIONS({ state }, payload)
     * @param {string[]} payload
     * @returns {void} nothing
     */
    GET_PERMISSIONS: ({ state }, payload) => {
      if (!Array.isArray(payload)) {
        throw new Error("payload must be an array");
      }

      const filteredPermissions = state.permissions.filter((item) =>
        payload.includes(item.name)
      );

      if (filteredPermissions.length) {
        filteredPermissions.forEach((permission) => {
          const subPermissions = {};
          for (const subPermission of permission?.permission?.sub_permissions) {
            subPermissions[subPermission.name] = true;
          }
          state.sub_Permissions[permission.name] = subPermissions;
        });
      }
    },

    avatar_change: ({ state }, payload) => {
      state.avatar = payload;
    },
  },
};
