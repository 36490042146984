export default [
  {
    path: "/add-new-page",
    name: "add-page",
    component: () => import("@/views/staticPage/addStaticPage/index.vue"),
    meta: {
      permissions: {
        pages: { store: true },
      },
    },
  },
  {
    path: "/edit-page/:id",
    name: "edit-page",
    component: () => import("@/views/staticPage/addStaticPage/index.vue"),
    meta: {
      permissions: {
        pages: { update: true },
      },
    },
  },
  {
    path: "/static-page-list",
    name: "staticPage",
    component: () => import("@/views/staticPage/staticPageList/index.vue"),
    meta: {
      permissions: {
        pages: { index: true },
      },
    },
  },
];
