export default [
  {
    path: "/show-country/:countryName",
    name: "show-country",
    component: () => import("@/views/settings/countries/showCountry/index.vue"),
    meta: {
      pageTitle: "g.breadCrumb/listOfStates",
      breadcrumb(route) {
        const countryName = route.params.countryName;
        return [
          {
            text: "g.breadCrumb/listOfCountries",
            to: {
              name: "countries",
            },            active: false,
          },
          {
            text: countryName,
            active: true,
          },
        ];
      },
    },
  },
  {
    // path: "/show-country/:id/state/:stateId",
    path: "/show-country/:countryName/:stateName",
    name: "show-city",
    component: () => import("@/views/settings/countries/showState"),
    meta: {
      pageTitle: "g.breadCrumb/listOfCities",
      breadcrumb(route) {
        const countryName = route.params.countryName;
        const stateName = route.params.stateName;
        return [
          {
            text: "g.breadCrumb/listOfCities",
            to: {
              name: "countries",
            },
            active: false,
          },
          {
            text: countryName,
            to: {
              name: "show-country",
              params: {
                countryName: countryName,
              },
            },
            active: false,
          },
          {
            text: stateName,

            active: true,
          },
        ];
      },
    },
  },
  {
    path: "/countries-list",
    name: "countries",
    component: () =>
      import("@/views/settings/countries/countriesList/index.vue"),
    pageTitle: "g.countries_list",
    breadcrumb: [
      {
        text: "g.countries_list",
        active: true,
      },
    ],
  },
];
