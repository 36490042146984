export default {
    empty(object) {
        return [null, undefined, '', ' ', [], {}, NaN].includes(object);
    },

    isNotEmpty(object) {
        return ![null, undefined, '', ' ', [], {}, NaN].includes(object);
    },

    isEmptyArray(object) {
        return this.isArray(object) && object.length === 0;
    },

    isNotEmptyArray(object) {
        return this.isArray(object) && object.length > 0;
    },

    isset(object, ...keys) {
        if (this.isArray(object)) {
            return this.isNotEmptyArray(object);
        }
        if (this.empty(object)) {
            return false;
        }
        if (this.empty(keys)) {
            return this.isNotEmpty(object);
        }
        let temp = object;
        for (let key of keys) {
            if (this.empty(temp[key])) {
                return false;
            }
            temp = temp[key];
        }
        return true;
    },

    isNull(object) {
        return object === null;
    },

    isUndefined(object) {
        return object === undefined;
    },

    isString(object) {
        return typeof object === 'string';
    },

    isNumber(object) {
        return typeof object === 'number';
    },

    isBoolean(object) {
        return typeof object === 'boolean';
    },

    isObject(object) {
        return typeof object === 'object';
    },

    isArray(object) {
        return Array.isArray(object);
    },

    inArray(object, array) {
        return array.includes(object);
    },

    ucfirst(string) {
        return string?.charAt(0).toUpperCase() + string?.slice(1);
    },

    ucwords(string) {
        return string.split(' ').map(word => this.ucfirst(word)).join(' ');
    },

    upperCase(string) {
        return string.toUpperCase();
    },

    lowerCase(string) {
        return string.toLowerCase();
    },

    camelCase(string) {
        return string.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    },

    snakeCase(string) {
        return string.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '_');
    },

    kebabCase(string) {
        return string.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '-');
    },

    titleCase(string) {
        return string.toLowerCase().split(' ').map(word => this.ucfirst(word)).join(' ');
    },

    randomString(length = 10) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let index = 0; index < length; index++) {
            result += characters?.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },

    randomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    randomFloat(min, max) {
        return Math.random() * (max - min) + min;
    },

    randomArrayElement(array) {
        return array[Math.floor(Math.random() * array.length)];
    },

    randomArrayElements(array, count = 1) {
        const shuffled = array.sort(() => 0.5 - Math.random());
        return shuffled.slice(0, count);
    },

    trim(string, length) {
        return string.length > length ? `${string.substring(0, length)}...` : string
    },

    ltrim(string, length) {
        return string.length > length ? `${string.substring(string.length - length, string.length)}...` : string
    },

    rtrim(string, length) {
        return string.length > length ? `${string.substring(0, length)}...${string.substring(string.length - length, string.length)}` : string
    },
}
