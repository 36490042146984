export default [
  {
    path: "/delivery-shifts-add",
    name: "DeliveryShiftsAdd",
    component: () =>
      import("@/views/delivery-shifts/DeliveryShiftsAdd/index.vue"),
  },
  {
    path: "/delivery-shifts-edit/:id",
    name: "DeliveryShiftsEdit",
    component: () =>
      import("@/views/delivery-shifts/DeliveryShiftsAdd/index.vue"),
  },
  {
    path: "/delivery-shifts-list",
    name: "DeliveryShiftsList",
    component: () =>
      import("@/views/delivery-shifts/DeliveryShiftsList/index.vue"),
  },
];
