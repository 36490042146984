export default [
  {
    path: "/add-notification",
    name: "add-notification",
    component: () => import("@/views/notifications/addNotification/index.vue"),
    meta: {
      permissions: { notifications: { broadcast: true } },
    },
  },
  {
    path: "/notifications-list",
    name: "notifications",
    component: () =>
      import("@/views/notifications/notificationsList/index.vue"),
    meta: {
      permissions: { notifications: { index: true } },
    },
  },
];
