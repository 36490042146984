import useJwt from "@/auth/jwt/useJwt";
import Store from "@/store";
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    (localStorage.getItem("userData") || Store.state?.userData) &&
    localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  );
};

export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

export const getUserRoles = () =>
  JSON.parse(localStorage.getItem("userData"))?.roles?.filter(
    (ele) => ele.guard_name === "web"
  )[0]?.permissions;
export const getRoutePermissionCheck = (
  requiredPermission,
  wholePermissionData
) => {
  if (!requiredPermission) {
    return true;
  }
  const check = Object.keys(requiredPermission).flatMap((permission) => {
    return Object.keys(requiredPermission[permission]).map((subPermission) => {
      return wholePermissionData[permission][subPermission];
    });
  });
  return check.every((ele) => ele);
};

export const handelPermission = (permissionsWholeData) => {
  const newPermissionsObj = {};
  permissionsWholeData.forEach((singlePermissionObj) => {
    const singlePermissionSubPermissions = {};
    singlePermissionObj.subPermissions.forEach((supPermissionElement) => {
      singlePermissionSubPermissions[supPermissionElement.name] =
        supPermissionElement.is_checked;
    });

    newPermissionsObj[singlePermissionObj.name] = {
      ...singlePermissionSubPermissions,
      ...newPermissionsObj[singlePermissionObj.name],
    };
  });
  return newPermissionsObj;
};

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "Super Admin") return "/";
  return { name: "login" };
};
