export default [
  {
    path: "/show-message/:id",
    name: "show-message",
    component: () => import("@/views/messages/showMessage/index.vue"),
    meta: { permissions: { contacts: { show: true } } },
  },
  {
    path: "/messages-list",
    name: "messages",
    component: () => import("@/views/messages/messagesList/index.vue"),
    meta: { permissions: { contacts: { index: true } } },
  },
];
