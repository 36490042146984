export default [
  {
    path: "/",
    name: "dashboard",
    component: () => import("@/views/dashboard/index.vue"),
    meta: {
      pageTitle: "g.dashboard",
      breadcrumb: [
        {
          text: "g.dashboard",
          active: true,
        },
      ],
    },
  },
];
