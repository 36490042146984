export default [
  {
    path: "/platforms-list",
    name: "platforms-list",
    component: () =>
      import("@/views/settings/platformsState/platformsList/index.vue"),
    meta: {
      pageTitle: "settings/roles/list-roles",
      breadcrumb: [
        {
          text: "settings/platformsState/maintenance",
          active: true,
        },
      ],
    },
  },
  {
    path: "/edit-platform/:id",
    name: "edit-platform",
    component: () =>
      import("@/views/settings/platformsState/editPlatform/index.vue"),
    meta: {
      pageTitle: "settings/roles/edit-role",
      breadcrumb: [
        {
          text: "settings/roles/edit-role",
          active: true,
        },
      ],
    },
  },
];
