export default [
  {
    path: "/brands-list",
    name: "brands",
    component: () => import("@/views/brands/brandsList/index.vue"),
    meta: {
      permissions: { brands: { index: true } },
    },
  },
];
