export default [
  {
    path: "/add-product",
    name: "add-product",
    component: () => import("@/views/products/addProduct/index.vue"),
    permissions: {
      products: {
        store: true,
      },
    },
  },
  {
    path: "/show-product/:catalogSlug/:slug",
    name: "show-product",
    component: () => import("@/views/products/singleProduct/show/index.vue"),
    permissions: {
      products: {
        show: true,
      },
    },
  },
  {
    path: "/product-review/:slug",
    name: "product-review",
    component: () => import("@/views/products/singleProduct/reviews/index"),
    meta: {
      permissions: {
        reviews: {
          index: true,
        },
      },
    },
  },
  {
    path: "/products-list",
    name: "products",
    component: () => import("@/views/products/productsList/index.vue"),
    meta: {
      permissions: {
        products: {
          index: true,
        },
      },
    },
  },
];
