export default [
  {
    path: "/reviews-list",
    name: "reviews-list",
    component: () => import("@/views/reviews/reviewsList/index.vue"),
    meta: {
      permissions: {
        reviews: { index: true },
      },
    },
  },
];
