import app from "./index";
import { getMessaging, getToken } from "firebase/messaging";

const messaging = getMessaging(app);

getToken(messaging, {
  vapidKey:
    "BCRdrzkhGsl2J7pC7XT-JIQtlWwfMC_ys2IokBhecE7nus2kxhI_CTus9ymnd-z0ofV1nAJpbQSjMkLpm-hqZCE",
})
  .then((res) => {
    localStorage.setItem("deviceToken", res);
  })
  .catch((err) => {});
