export default [
  {
    path: "/vendors-list",
    name: "vendors",
    component: () => import("@/views/vendors/vendorsList/index.vue"),
    meta: {
      permissions: { vendors: { index: true } },
      pageTitle: "routes/vendors/vendors_list",
      breadcrumb: [
        {
          text: "routes/vendors/vendors_list",
          active: true,
        },
      ],
    },
  },
];
