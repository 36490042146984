export default [
  {
    path: "/payment-methods",
    name: "payment-methods",
    component: () => import("@/views/paymentMethod/index.vue"),
    meta: {
      permissions: {
        "payment-methods": { show: true },
      },
    },
  },
];
