export default [
  {
    path: "/edit-delivery-boy/:id",
    name: "edit-delivery-boy",
    component: () =>
      import("@/views/delivery/deliveryBoys/editDeliveryBoys/index.vue"),
    meta: {
      permissions: { "delivery-boys": { update: true } },
    },
  },
  {
    path: "/delivery-boys-list",
    name: "delivery-boys-list",
    component: () =>
      import("@/views/delivery/deliveryBoys/deliveryBoysList/index.vue"),
    meta: {
      permissions: { "delivery-boys": { index: true } },
    },
  },
  {
    path: "/delivery-requests-list",
    name: "delivery-requests-list",
    component: () =>
      import(
        "@/views/delivery/deliveryRequests/deliveryRequestsList/index.vue"
      ),
    meta: {
      permissions: { "delivery-boys": { update: true } },
    },
  },
];
