export default [
  {
    path: "/categories-list",
    name: "categories",
    component: () => import("@/views/categories/categoriesList/index.vue"),
    meta: {
      pageTitle: "g.categories_list",
      permissions: {
        categories: {
          index: true,
        },
      },
      breadcrumb: [
        {
          text: "g.categories_list",
          active: true,
        },
      ],
    },
  },
];
