import Vue from "vue";
import VueRouter from "vue-router";
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
  getRoutePermissionCheck,
  handelPermission,
  getUserRoles,
} from "@/auth/utils";

import brands from "./routes/brands";
import categories from "./routes/categories";
import dashboard from "./routes/dashboard";
import settings from "./routes/settings";
import users from "./routes/users";
import vendors from "./routes/vendors";
import catalog from "./routes/products";
import products from "./routes/catalog";
import authentication from "./routes/authentication";
import profile from "@/router/routes/profile";
import coupons from "./routes/coupons";
import messages from "./routes/messages";
import notifications from "./routes/notifications";
import orders from "./routes/orders";
import delivery from "./routes/delivery";
import faq from "./routes/faq";
import deliveryShifts from "./routes/delivery-shifts";
import paymentMethod from "./routes/paymentMethod";
import staticPage from "./routes/staticPage";
import platformsState from "./routes/settings/platformsState";
import reviews from "./routes/reviews";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Home",
        permissions: { statistics: { index: true } },
        breadcrumb: [
          {
            text: "g.home",
            active: true,
          },
        ],
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/permission-denied",
      name: "permission-denied",
      component: () => import("@/views/permissionDenied/index.vue"),
    },
    {
      path: "*",
      redirect: "error-404",
    },
    ...brands,
    ...categories,
    ...dashboard,
    ...settings,
    ...users,
    ...vendors,
    ...catalog,
    ...products,
    ...authentication,
    ...profile,
    ...coupons,
    ...messages,
    ...notifications,
    ...orders,
    ...delivery,
    ...faq,
    ...deliveryShifts,
    ...paymentMethod,
    ...staticPage,
    ...platformsState,
    ...reviews,
  ],
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (!canNavigate(to)) {
    if (
      (!isLoggedIn && to.name !== "login") ||
      (!isLoggedIn && to.name !== "signup")
    )
      return next({ name: "login" });
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    if (to.name === "login") {
      return next({ name: "home" });
    }
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }
  if (to.name !== "login") {
    const storedUserData = getUserRoles();
    if (storedUserData) {
      const canGo = getRoutePermissionCheck(
        to.meta.permissions,
        handelPermission(storedUserData)
      );
      if (!canGo && to.name !== "permission-denied") {
        return next({ name: "permission-denied" });
      }
    }
  }

  return next();
});

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
