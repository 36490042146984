import app from "./index";
import { getMessaging, onMessage } from "firebase/messaging";
import store from "@/store/index";
import toast from "@/libs/toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
  
  const notificationTitle = payload.data?.title || "Default Title";
  const notificationBody = payload.data?.body || "Default Body";

  // Show a toast notification using the title and body from payload
  toast({
    component: ToastificationContent,
    position: "top-right",
    props: {
      title: notificationTitle, // Translate title
      icon: "InfoIcon",
      variant: "info",
      text: notificationBody, // Translate body
    },
  });

  console.log("Message received: ", payload);
  store.dispatch("get_notification");
});
