export default [
  {
    path: "/edit-coupon/:id",
    name: "edit-coupon",
    component: () => import("@/views/coupons/editCoupon/index.vue"),
    meta: {
      permissions: { coupons: { update: true } },
    },
  },
  {
    path: "/coupons-list",
    name: "couponsList",
    component: () => import("@/views/coupons/couponsList/index.vue"),
    meta: {
      permissions: { coupons: { index: true } },
    },
  },
];
